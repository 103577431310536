import { UserActionTypes } from '../action/userAction'

const userReducer = (state = {
  userRegister: {},
  userInfo: {},
  userCheckToken: {},
  userLoginCheckToken: {},
  userActivation: {},
  userResetEmail: {},
  userResetInfo: {},
}, action) => {
  switch(action.type){
    case UserActionTypes.POST_REGISTER:
      return Object.assign({}, state, { userRegister: action.payload })
    case UserActionTypes.POST_LOGIN:
      return Object.assign({}, state, { userInfo: action.payload })
    case UserActionTypes.GET_CHECK_TOKEN:
      return Object.assign({}, state, { userCheckToken: action.payload })
    case UserActionTypes.GET_LOGIN_CHECK_TOKEN:
      return Object.assign({}, state, { userLoginCheckToken: action.payload })
    case UserActionTypes.POST_USER_ACTIVATION_CODE:
      return Object.assign({}, state, { userActivation: action.payload })
    case UserActionTypes.POST_FORGOT_PASSWORD_EMAIL:
      return Object.assign({}, state, { userResetEmail: action.payload })
    case UserActionTypes.PATCH_RESET_PASSWORD:
      return Object.assign({}, state, { userResetInfo: action.payload })
    default:
      return state
  }
}
export default userReducer