import React, { useState } from 'react';
import './Cost.css';
import 'antd/dist/antd.css';
import { Layout, Table, Row, Col, DatePicker, Typography, Button } from 'antd';
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
import SiderPage from '../../components/Sider/Sider'
const { Content} = Layout;
const { Text } = Typography;

function Cost(props) {
  // const [ status1, setStatus1 ] = useState(true)
  // const [ taskCreate, setTaskCreate ] = useState(false)
  // const [ recordCreate, setRecordCreate ] = useState(false)
  // const [ flowCreate, setFlowCreate ] = useState(false)

  function handleChange(value) {
  }
  function onChange(value, dateString) {
  }
  
  function onOk(value) {
  }
  function searchCost() {
  }
  const columns = [
    {
      title: '序号',
      dataIndex: '序号',
    },
    {
      title: '订单号',
      dataIndex: '订单号',
    },
    {
      title: '充值时间',
      dataIndex: '充值时间',
    },
    {
      title: '充值金额',
      dataIndex: '充值金额',
    },
    {
      title: '余额',
      dataIndex: '余额',
    },
    {
      title: '渠道',
      dataIndex: '渠道',
    },
  ];
  const data = [
    {
      key: '1',
      序号: 'XXXXXXXXX',
      订单号: 32,
      充值时间: '20190711 12:09:09',
      充值金额: '¥ 100.00',
      余额: '¥ 100.00',
      渠道: '微信',
    },
    {
      key: '2',
      序号: 'XXXXXXXXX',
      订单号: 32,
      充值时间: '20190711 12:09:09',
      充值金额: '¥ 100.00',
      余额: '¥ 100.00',
      渠道: '微信',
    },
    {
      key: '3',
      序号: 'XXXXXXXXX',
      订单号: 32,
      充值时间: '20190711 12:09:09',
      充值金额: '¥ 100.00',
      余额: '¥ 100.00',
      渠道: '支付宝',
    },
  ];
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SiderPage/> */}
      <Layout>
        <HeaderPage style={{ background: '#fff', padding: 0 }} history={props.history}/>
        <Content className="content">
          <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
            <Row>
              <Col className="mb-10">
                当前余额: <Text type="danger">¥ 20.00</Text>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col className="content-filter">
                <span className="task">开始时间: </span>
                <DatePicker showTime placeholder="Select Time" onChange={onChange} onOk={onOk} />
              </Col>
              <Col className="content-filter">
                <span className="task">结束时间: </span>
                <DatePicker showTime placeholder="Select Time" onChange={onChange} onOk={onOk} />
              </Col>
              <Col className="content-filter">
                <Button type="primary" shape="round" icon="search" onClick={searchCost}>搜索</Button>
              </Col>
              <Col className="content-filter">
                <Button type="primary" shape="round" icon="money-collect" onClick={searchCost}>充值</Button>
              </Col>
            </Row>
            
            <Table className="table-info" columns={columns} dataSource={data} bordered />
          </div>
          {/* <Modal
            title="新建任务"
            visible={taskCreate}
            onOk={taskCreateOk}
            onCancel={taskCreateCancel}
          >
            <TaskCreate />
          </Modal> */}
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

export default Cost;


