import axios from 'axios'
import { message } from 'antd'
export const UserActionTypes = {
  POST_REGISTER:'POST_REGISTER',
  POST_LOGIN:'POST_LOGIN',
  GET_CHECK_TOKEN:'GET_CHECK_TOKEN',
  GET_LOGIN_CHECK_TOKEN:'GET_LOGIN_CHECK_TOKEN',
  POST_USER_ACTIVATION_CODE:'POST_USER_ACTIVATION_CODE',
  POST_FORGOT_PASSWORD_EMAIL:'POST_FORGOT_PASSWORD_EMAIL',
  PATCH_RESET_PASSWORD:'PATCH_RESET_PASSWORD',
}

export const UserActionCreators = {
  PostLoginAction(username, password){
    const data = {
      username,
      password,
    }
    return async (dispatch) => {
      try {
        const result = await axios.post('/gateway/api/v1/tokens', data)
          if (result.status === 201) {
            dispatch({type: UserActionTypes.POST_LOGIN, payload: result.data})
          }
      } catch (e) {
        dispatch({type: UserActionTypes.POST_LOGIN, payload: 'error'})
        message.error(e.response.data.error)
        console.error(e)
      }
    }
  },
  PostRegisterAction(username, password,email){
    const data = {
      username,
      password,
      email,
    }
    return async (dispatch) => {
      try {
          const result = await axios.post('/gateway/api/v1/users', data)
          if (result.status === 201) {
            message.info('注册成功,请登录验证!')
            dispatch({type: UserActionTypes.POST_REGISTER, payload: result.data})
          }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  GetCheckTokenAction(token){
    return async (dispatch) => {
      try {
        const result = await axios.get('/gateway/api/v1/tokens/' + token)
          if (result.status === 200) {
            dispatch({type: UserActionTypes.GET_CHECK_TOKEN, payload: result.data})
          }
      } catch (e) {
        console.error(e)
        // message.error(e.response.data.error)
        dispatch({type: UserActionTypes.GET_CHECK_TOKEN, payload: 'error'})
      }
    }
  },
  GetLoginCheckTokenAction(token){
    return async (dispatch) => {
      try {
        const result = await axios.get('/gateway/api/v1/tokens/' + token)
          if (result.status === 200) {
            dispatch({type: UserActionTypes.GET_LOGIN_CHECK_TOKEN, payload: result.data})
          }
      } catch (e) {
        console.error(e)
        dispatch({type: UserActionTypes.GET_LOGIN_CHECK_TOKEN, payload: 'error'})
      }
    }
  },
  PostUserActivationCodeAction(id,activationCode){
    const data = {
      activationCode,
    }
    return async (dispatch) => {
      try {
          const instance = axios.create({
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
          }) 
          const result = await instance.patch('/gateway/api/v1/users/' + id, data)
          if (result.status === 200) {
            message.info('激活成功,请再次登录!')
            dispatch({type: UserActionTypes.POST_USER_ACTIVATION_CODE, payload: result.data})
          }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
        dispatch({type: UserActionTypes.POST_USER_ACTIVATION_CODE, payload: 'error'})
      }
    }
  },
  PostForgotPasswordEmailAction(email){
    const data = {
      email,
    }
    return async (dispatch) => {
      try {
          // const instance = axios.create({
          //   headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
          // }) 
          const result = await axios.post('/gateway/api/v1/users/password', data)
          if (result.status === 201) {
            message.info('重置密码链接已发送邮箱!')
            dispatch({type: UserActionTypes.POST_FORGOT_PASSWORD_EMAIL, payload: result.data})
          }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
        dispatch({type: UserActionTypes.POST_FORGOT_PASSWORD_EMAIL, payload: 'error'})
      }
    }
  },
  ResetPasswordAction(userID, password, resetPasswordCode){
    const data = {
      password,
      resetPasswordCode,
    }
    return async (dispatch) => {
      try {
          const result = await axios.patch('/gateway/api/v1/users/' + userID + '/password', data)
          if (result.status === 200) {
            message.info('密码更新成功，请重新登录!')
            dispatch({type: UserActionTypes.PATCH_RESET_PASSWORD, payload: 'sucsseful'})
          }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
        dispatch({type: UserActionTypes.PATCH_RESET_PASSWORD, payload: 'error'})
      }
    }
  },
}