import axios from 'axios'
import { message } from 'antd'
export const TaskActionTypes = {
  GET_ONGOING_TASK:'GET_ONGOING_TASK',
  GET_ALL_TASKS:'GET_ALL_TASKS',
  POST_NEW_TASKS:'POST_NEW_TASKS',
  STOP_TASKS:'STOP_TASKS',
  POST_NEW_RECORD:'POST_NEW_RECORD',
  STOP_RECORD:'STOP_RECORD',
  POST_NEW_RTMPS:'POST_NEW_RTMPS',
  STOP_RTMPS:'STOP_RTMPS',
  POST_RECORD_URL:'POST_RECORD_URL',
}

export const TaskActionCreators = {
  GetCheckTokenAction(token){
    return async (dispatch) => {
      try {
        const result = await axios.get('/gateway/api/v1/tokens/' + token)
          if (result.status === 200) {
            dispatch({type: TaskActionTypes.GET_ONGOING_TASK, payload: result.data})
          }
      } catch (e) {
        console.error(e)
        dispatch({type: TaskActionTypes.GET_CHECK_TOKEN, payload: 'error'})
      }
    }
  },
  GetAllTasksAction(pageNum, pageSize,searchFilter){
    return async (dispatch) => {
      try {
        const params = searchFilter
        Object.assign(params, {pageNum: pageNum}, { pageSize: pageSize})
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.get('/zukvnc-test/api/v1/tasks', { params })
        if (result.status === 200) {
          dispatch({type: TaskActionTypes.GET_ALL_TASKS, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  PostNewTasksAction(url, callback, region){
    return async (dispatch) => {
      try {
        const  data = {
          url,
          callback,
          region,
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.post('/zukvnc-test/api/v1/tasks', data)
        if (result.status === 202) {
          dispatch({type: TaskActionTypes.POST_NEW_TASKS, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  StopTasksAction(display){
    return async (dispatch) => {
      try {
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.delete('/zukvnc-test/api/v1/tasks/' + display)
        if (result.status === 204) {
          dispatch({type: TaskActionTypes.STOP_TASKS, payload: 'delete done!'})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  PostNewRecordAction(display, recordName, format, onlyAudio){
    return async (dispatch) => {
      try {
        const  data = {
          recordName,
          format,
          onlyAudio,
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.post('/zukvnc-test/api/v1/tasks/' + display + '/records', data)
        if (result.status === 202) {
          dispatch({type: TaskActionTypes.POST_NEW_RECORD, payload: 'new record!'})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  StopRecordAction(display){
    return async (dispatch) => {
      try {
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.delete('/zukvnc-test/api/v1/tasks/' + display + '/records')
        if (result.status === 204) {
          dispatch({type: TaskActionTypes.STOP_RECORD, payload: 'delete done!'})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  PostNewRTMPAction(display, rtmpUrl){
    return async (dispatch) => {
      try {
        const  data = {
          rtmpUrl,
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.post('/zukvnc-test/api/v1/tasks/' + display + '/rtmps', data)
        if (result.status === 202) {
          dispatch({type: TaskActionTypes.POST_NEW_RTMPS, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  StopRTMPAction(display, rtmpID){
    return async (dispatch) => {
      try {
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.delete('/zukvnc-test/api/v1/tasks/' + display + '/rtmps/' + rtmpID)
        if (result.status === 204) {
          dispatch({type: TaskActionTypes.STOP_RTMPS, payload: 'delete done!'})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  PostRecordUrlAction(display, recordName, expireTime){
    return async (dispatch) => {
      try {
        const data = {
          expireTime
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.post('/zukvnc-test/api/v1/stopped-tasks/' + display + '/records/' + recordName + '/urls', data)
        if (result.status === 201) {
          dispatch({type: TaskActionTypes.POST_RECORD_URL, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
}