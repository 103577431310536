import axios from 'axios'
import { message } from 'antd'
export const FormActionTypes = {
  GET_TICKET:'GET_TICKET',
  POST_NEW_TICKET:'POST_NEW_TICKET',
  POST_NEW_REPLY:'POST_NEW_REPLY',
  CLOSE_TICKET:'CLOSE_TICKET',
}

export const FormActionCreators = {
  GetTicketAction(page, pageSize, id){
    return async (dispatch) => {
      try {
        const params = {
          page,
          pageSize,
          userId: id
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.get('/gateway/api/v1/tickets', { params })
        if (result.status === 200) {
          dispatch({type: FormActionTypes.GET_TICKET, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  SearchTicketAction(startTime,endTime,id){
    return async (dispatch) => {
      try {
        const params = {
          userId: id,
          startedTime: startTime,
          stoppedTime: endTime,
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.get('/gateway/api/v1/tickets', { params })
        if (result.status === 200) {
          dispatch({type: FormActionTypes.GET_TICKET, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  PostFormAction(title, content){
    return async (dispatch) => {
      try {
        const data = {
          title,
          content,
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.post('/gateway/api/v1/tickets', data)
        if (result.status === 201) {
          dispatch({type: FormActionTypes.POST_NEW_TICKET, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  PostReplyAction(ticketId, content){
    return async (dispatch) => {
      try {
        const data = {
          content,
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.post('/gateway/api/v1/tickets/' +  ticketId + '/posts', data)
        if (result.status === 201) {
          dispatch({type: FormActionTypes.POST_NEW_REPLY, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
  CloseFormAction(ticketId){
    return async (dispatch) => {
      try {
        const data = {
          closed: true
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.patch('/gateway/api/v1/tickets/' + ticketId, data)
        if (result.status === 200) {
          dispatch({type: FormActionTypes.CLOSE_TICKET, payload: result.data})
        }
      } catch (e) {
        message.error(e.response.data.error)
        console.log(e.response)
      }
    }
  },
}