import React, { useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { UserActionCreators } from '../../action/userAction'
import 'antd/dist/antd.css';
import './Sider.css';
import { Layout, Menu, Icon, message } from 'antd';
const { Sider } = Layout;


function SiderPage(props) {
  const [isAdmin, setIsAdmin] = useState(false)
  const [siderList, setSiderList] = useState([])
  const tokenRef = useRef()

  useEffect(()=>{
    tokenRef.current = props.userCheckToken
    const userToken = localStorage.getItem('user_token')
    if(userToken === null || userToken.length == 0){
      message.info('请登录！')
      props.history.push('/login')
    }
    if(userToken){
      props.getCheckToken('Bearer ' + userToken)
    }
  },[])

  // 请求token接口后检查token是否有效 无效跳转login页面
  useEffect(() => {
    if (tokenRef.current !== props.userCheckToken) {
      if (props.userCheckToken == 'error') {
      props.history.push('/login')
      message.error('请登录!')
      return
      }
      if(props.userCheckToken.roles['zukvnc-test'] === 'ADMIN') {
        setIsAdmin(true)
        const list = [
          {
            key: '/home',
            icon: 'desktop',
            title: '主页',
          },
          {
            key: '/task/info',
            icon: 'schedule',
            title: '任务查询',
          },
          {
            key: '/instance/info',
            icon: 'schedule',
            title: '实例管理',
          },
          {
            key: '/cost/info',
            icon: 'money-collect',
            title: '费用管理',
          },
          {
            key: '/form/info',
            icon: 'form',
            title: '工单管理',
          },
          {
            key: '/info/info',
            icon: 'info-circle',
            title: '信息统计',
          },
        ]
        setSiderList(list)
      } else {
        setIsAdmin(false)
        const list = [
          {
            key: '/home',
            icon: 'desktop',
            title: '主页',
          },
          {
            key: '/task/info',
            icon: 'schedule',
            title: '任务查询',
          },
          {
            key: '/cost/info',
            icon: 'money-collect',
            title: '费用管理',
          },
          {
            key: '/form/info',
            icon: 'form',
            title: '工单管理',
          },
          {
            key: '/info/info',
            icon: 'info-circle',
            title: '信息统计',
          },
        ]
        setSiderList(list)
      }
    }
    
  },[props.userCheckToken])

  return (
    <Layout style={{ minHeight: '100vh', float: 'left', height:'100%' }}>
      <Sider collapsible>
        <div className="logo">ZukVNC</div>
        <Menu theme="dark" defaultSelectedKeys={['/home']} selectedKeys={[window.location.pathname]} mode="inline">
          {
            siderList.length !==0 ?
              siderList.map((item,index)=>{
                return (
                    <Menu.Item key={item.key}>
                      <NavLink to={item.key}>
                        <Icon type={item.icon} />
                        <span>{item.title}</span>
                      </NavLink>
                    </Menu.Item>
                )
              })
              :
              null
          }
        </Menu>
      </Sider>
    </Layout>
    
  );
}

const mapStateToProps = (state) => {
  return {
    userCheckToken: state.userReducer.userCheckToken,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    getCheckToken: bindActionCreators(UserActionCreators.GetCheckTokenAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(SiderPage)

