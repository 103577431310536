import { InstanceActionTypes } from '../action/instanceAction'

const instanceReducer = (state = {
  getInstanceInfo: [],
}, action) => {
  switch(action.type){
    case InstanceActionTypes.GET_INSTANCE:
      return Object.assign({}, state, { getInstanceInfo: action.payload })
    default:
      return state
  }
}
export default instanceReducer