import { TaskActionTypes } from '../action/taskAction'

const taskReducer = (state = {
  taskOnGoing: {},
  allTaskInfo: {},
  newTaskInfo: {},
  stopTaskInfo: {},
  newRecordInfo: {},
  stopRecordInfo: {},
  newRtmpInfo: {},
  stopRtmpInfo: {},
  postRecordUrlInfo: {},
}, action) => {
  switch(action.type){
    case TaskActionTypes.GET_ONGOING_TASK:
      return Object.assign({}, state, { taskOnGoing: action.payload })
    case TaskActionTypes.GET_ALL_TASKS:
      return Object.assign({}, state, { allTaskInfo: action.payload })
    case TaskActionTypes.POST_NEW_TASKS:
      return Object.assign({}, state, { newTaskInfo: action.payload })
    case TaskActionTypes.STOP_TASKS:
      return Object.assign({}, state, { stopTaskInfo: action.payload })
    case TaskActionTypes.POST_NEW_RECORD:
      return Object.assign({}, state, { newRecordInfo: action.payload })
    case TaskActionTypes.STOP_RECORD:
      return Object.assign({}, state, { stopRecordInfo: action.payload })
    case TaskActionTypes.POST_NEW_RTMPS:
      return Object.assign({}, state, { newRtmpInfo: action.payload })
    case TaskActionTypes.STOP_RTMPS:
      return Object.assign({}, state, { stopRtmpInfo: action.payload })
    case TaskActionTypes.POST_RECORD_URL:
      return Object.assign({}, state, { postRecordUrlInfo: action.payload })
    default:
      return state
  }
}
export default taskReducer