import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'

import './Home.css';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Card, Typography, Table } from 'antd';
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
import SiderPage from '../../components/Sider/Sider'
const { Content} = Layout;
const { Text } = Typography;

function Home(props) {
  const [ collapsed, setCollapsed ] = useState(false)
  const columns = [
    {
      title: '充值时间',
      dataIndex: '充值时间',
    },
    {
      title: '充值金额',
      dataIndex: '充值金额',
    },
    {
      title: '渠道',
      dataIndex: '渠道',
    },
  ];
  const data = [
    {
      key: '1',
      充值时间: '20190711 12:09:09',
      充值金额: '¥ 100.00',
      渠道: '微信',
    },
    {
      key: '2',
      充值时间: '20190711 12:09:09',
      充值金额: '¥ 100.00',
      渠道: '微信',
    },
    {
      key: '3',
      充值时间: '20190711 12:09:09',
      充值金额: '¥ 100.00',
      渠道: '支付宝',
    },
  ];

  const columnsForm = [
    {
      title: '工单编号',
      dataIndex: '工单编号',
    },
    {
      title: '发起时间',
      dataIndex: '发起时间',
    },
    {
      title: '标题',
      dataIndex: '标题',
    },
    {
      title: '状态',
      dataIndex: '状态',
    },
  ];
  const dataForm = [
    {
      key: '1',
      工单编号: 32,
      发起人: 'New York',
      发起时间: '20190711 12:09:09',
      标题: 'New York No. 1 Lake Park',
      状态: '未回复',
    },
    {
      key: '2',
      工单编号: 32,
      发起人: 'New York',
      发起时间: '20190711 12:09:09',
      标题: 'New York No. 1 Lake Park',
      状态: '已回复',
    },
    {
      key: '3',
      工单编号: 32,
      发起人: 'New York',
      发起时间: '20190711 12:09:09',
      标题: 'New York No. 1 Lake Park',
      状态: '已处理',
    },
  ];
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SiderPage /> */}
      <Layout>
        <HeaderPage style={{ background: '#fff', padding: 0 }} history={props.history}/>
        <Content className="content">
          <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
            <Row gutter={[16,16]}>
              <Col>
                <Card title="任务信息">
                  <Text className="taskItem">任务数(进行中): 20</Text>
                  <Text className="taskItem">录制数(进行中): 20</Text>
                  <Text className="taskItem">推流数(进行中): 20</Text>
                </Card>              
              </Col>
            </Row>
            <Row type="flex" justify="space-between" gutter={[16,16]}>
              <Col span={12}>
                <Card title="充值记录">
                  <Col>
                    当前余额: <Text type="danger">¥ 20.00</Text>
                  </Col>
                  <Table className="table-info" columns={columns} dataSource={data} />
                </Card>              
              </Col>
              <Col span={12}>
                <Card title="工单信息">
                  <Col>
                    工单总数: <Text>20</Text>
                  </Col>
                  <Table className="table-info" columns={columnsForm} dataSource={dataForm} />
                </Card>              
              </Col>
            </Row>
            <Row gutter={[16,16]}>
              <Col>
                <Card title="常用链接">
                  <NavLink to="/#" className="homeLink">产品概述</NavLink>
                  <NavLink to="/#" className="homeLink">案例代码</NavLink>
                  <NavLink to="/#" className="homeLink">API参考</NavLink>
                </Card>              
              </Col>
            </Row>
          </div>
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

export default Home;


