import React, { useState } from 'react';
import './UserPage.css';
import 'antd/dist/antd.css';
import { Layout, Table, Row, Col, Card, Tabs } from 'antd';
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
import SiderPage from '../../components/Sider/Sider'
const { Content} = Layout;
const { TabPane } = Tabs;

function UserPage() {
  const [ status1, setStatus1 ] = useState(true)

  function callback(key) {
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SiderPage />
      <Layout>
        <HeaderPage style={{ background: '#fff', padding: 0 }} />
        <Content className="content">
          <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="个人设置" key="1">
                Content of Tab Pane 1
              </TabPane>
              <TabPane tab="用户查询" key="2">
                Content of Tab Pane 2
              </TabPane>
            </Tabs>
          </div>
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

export default UserPage;


