import { InfoActionTypes } from '../action/infoAction'

const infoReducer = (state = {
  getUserInfo: [],
  getAllUserInfo: [],
}, action) => {
  switch(action.type){
    case InfoActionTypes.GET_USER_INFO:
      return Object.assign({}, state, { getUserInfo: action.payload })
    case InfoActionTypes.GET_ALL_USER_INFO:
      return Object.assign({}, state, { getAllUserInfo: action.payload })
    default:
      return state
  }
}
export default infoReducer