import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './Loading.css';
import 'antd/dist/antd.css';
import { Icon, Spin } from 'antd';
import { UserActionCreators } from '../../action/userAction'

function LoadingPage(props) {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <div className="loadingPage">
        <Spin indicator={antIcon} />
        <h1>Loading...</h1>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(LoadingPage);