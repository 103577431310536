import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { InstanceActionCreators } from '../../action/instanceAction'
import './InstancePage.css';
import 'antd/dist/antd.css';
import { Layout, Menu, Select, Table, Row, Col, DatePicker, Button, Popconfirm, Divider, Modal } from 'antd';
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
import SiderPage from '../../components/Sider/Sider'
const { Content } = Layout;
const { Option } = Select;

function Task(props) {
  const [ data, setData ] = useState([])
  const instanceInfoRef = useRef()

  useEffect(()=>{
    instanceInfoRef.current = props.instanceInfo
    props.getInstance()
  },[])
  
  useEffect(()=>{
    if(props.instanceInfo !== instanceInfoRef.current){
      const data = props.instanceInfo.map((item,index)=>{
        return (
          Object.assign({}, {key: index}, {createdTime: item.instance.createdTime}, {ec2InstanceId: item.instance.ec2InstanceId}, {ip:item.instance.ip}, {taskArn: item.instance.taskArn}, {region: item.region})
        )
      })
      setData(data)
    }
  },[props.instanceInfo])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'ec2InstanceId',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
    },
    {
      title: 'Task',
      dataIndex: 'taskArn',
    },
    {
      title: 'Region',
      dataIndex: 'region',
    },
    {
      title: 'CreatedTime',
      dataIndex: 'createdTime',
    },
  ];
  ;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SiderPage /> */}
      <Layout>
        <HeaderPage style={{ background: '#fff', padding: 0 }} history={props.history}/>
        <Content className="content">
          <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
            <h2>实例信息</h2>
            <Table className="table-info" columns={columns} dataSource={data} bordered pagination={false}/>
          </div>
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    instanceInfo: state.instanceReducer.getInstanceInfo,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    getInstance: bindActionCreators(InstanceActionCreators.GetInstanceAction, dispatch),
  }
}


export default connect(mapStateToProps, mapDsipatchToProps)(Task);


