import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TaskActionCreators } from '../../action/taskAction'
import './Task.css';
import 'antd/dist/antd.css';
import { Layout, Menu, Select, Table, Row, Col, DatePicker, Button, Popconfirm, Divider, Modal, Pagination, Tooltip, Input, message } from 'antd';
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
import LoadingPage from '../../components/Loading/Loading'

const { Content } = Layout;
const { Option } = Select;

function Task(props) {
  const [ loading, setLoading ] = useState(true)
  const [ page, setPage ] = useState(0)
  const [ pageSize, setPageSize ] = useState(20)
  const [ totalCounts, setTotalCounts ] = useState(1)
  const [ rowID, setRowID ] = useState('')
  const [ recordNameDownload, setRecordNameDownload ] = useState('')
  const [ searchFilter, setSearchFilter ] = useState({})
  //任务相关
  const [ url, setUrl ] = useState('')
  const [ callback, setCallback ] = useState('')
  const [ region, setRegion ] = useState('')

  //录像相关
  const [ recordName, setRecordName ] = useState('')
  const [ recordFormat, setRecordFormat ] = useState('')
  const [ isAudio, setIsAudio ] = useState(false)

  //推流相关
  const [ rtmpUrl, setRtmpUrl ] = useState('')
  const [ rtmpID, setRtmpID ] = useState('')

  const [ tableData, setTableData ] = useState([])

  //对话框弹出
  const [ taskCreate, setTaskCreate ] = useState(false)
  const [ recordCreate, setRecordCreate ] = useState(false)
  const [ flowCreate, setFlowCreate ] = useState(false)
  const tokenRef = useRef()
  const allTaskInfoRef = useRef()
  const newTaskInfoRef = useRef()
  const stopTaskInfoRef = useRef()
  const newRecordInfoRef = useRef()
  const stopRecordInfoRef = useRef()
  const newRtmpInfoRef = useRef()
  const stopRtmpInfoRef = useRef()
  const postRecordUrlInfoRef = useRef()

  useEffect(() => {
    tokenRef.current = props.userCheckToken
    allTaskInfoRef.current = props.allTaskInfo
    newTaskInfoRef.current = props.newTaskInfo
    stopTaskInfoRef.current = props.stopTaskInfo
    newRecordInfoRef.current = props.newRecordInfo
    stopRecordInfoRef.current = props.stopRecordInfo
    newRtmpInfoRef.current = props.newRtmpInfo
    stopRtmpInfoRef.current = props.stopRtmpInfo
    postRecordUrlInfoRef.current = props.postRecordUrlInfo
  },[])

  useEffect(() => {
    getData()
  },[props.userCheckToken])

  useEffect(() => {
    if(allTaskInfoRef.current !== props.allTaskInfo){
      setTotalCounts(props.allTaskInfo.totalCount)
      const taskArray = props.allTaskInfo.data.map((item, index) => {
        const startTime = item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm:ss') : 0
        const stopTime = item.stopTime ? moment(item.stopTime).format('YYYY-MM-DD HH:mm:ss') : 0
        const totalTime = !item.startTime ? 0 : item.stopTime ? moment.duration(item.stopTime - item.startTime).as('minutes') : moment.duration(moment(new Date())- item.startTime).as('minutes')
        return (
          Object.assign(item, { key: item.id, startTime: startTime, stopTime: stopTime, totalTime: totalTime.toFixed(2)})
        )
      })
      setTableData(taskArray)
      setLoading(false)
    }
  },[props.allTaskInfo])

  useEffect(()=>{
    if(newTaskInfoRef.current !== props.newTaskInfo){
      message.info('已新建任务！')
      props.getAllTasks(page, pageSize, searchFilter)
    }
  },[props.newTaskInfo])

  useEffect(()=>{
    if(stopTaskInfoRef.current !== props.stopTaskInfo){
      message.info('已停止！')
      setTimeout(() => {
        props.getAllTasks(page, pageSize, searchFilter)
      }, 2000);
    }
  },[props.stopTaskInfo])

  useEffect(()=>{
    if(newRecordInfoRef.current !== props.newRecordInfo){
      message.info('已新建录像！')
      props.getAllTasks(page, pageSize, searchFilter)
    }
  },[props.newRecordInfo])

  useEffect(()=>{
    if(stopRecordInfoRef.current !== props.stopRecordInfo){
      message.info('已停止录像！')
      setTimeout(() => {
        props.getAllTasks(page, pageSize, searchFilter)
      }, 2000);
    }
  },[props.stopRecordInfo])

  useEffect(()=>{
    if(newRtmpInfoRef.current !== props.newRtmpInfo){
      message.info('已推流！')
      props.getAllTasks(page, pageSize, searchFilter)
    }
  },[props.newRtmpInfo])

  useEffect(()=>{
    if(stopRtmpInfoRef.current !== props.stopRtmpInfo){
      message.info('已停止推流！')
      props.getAllTasks(page, pageSize, searchFilter)
    }
  },[props.stopRtmpInfo])

  useEffect(()=>{
    if(postRecordUrlInfoRef.current !== props.postRecordUrlInfo){
      message.info('马上开始下载!')
      window.open(props.postRecordUrlInfo.url)
    }
  },[props.postRecordUrlInfo])

  function changePage(page, pageSize){
    props.getAllTasks(page - 1, pageSize, searchFilter)
  }

  // 获取task数据
  function getData() {
    if (Object.keys(props.userCheckToken).length !== 0){
      if(props.userCheckToken.roles['zukvnc-test'] === 'ADMIN') {
        props.getAllTasks(page, pageSize, searchFilter)
      } else {
        const data = Object.assign({}, searchFilter, { userId : localStorage.getItem('user_id')})
        setSearchFilter(data)
        props.getAllTasks(page, pageSize, { userId : localStorage.getItem('user_id')})
      }
    }
  }
  //搜索相关
  function taskSearchChange(value) {
    const data = Object.assign({}, searchFilter, { stopped : value})
    setSearchFilter(data)
  }
  function startTimeChange(value, dateString) {
    const data = Object.assign({}, searchFilter, { startedTime : moment(dateString).valueOf()})
    setSearchFilter(data)
  }
  function stopTimeChange(value, dateString) {
    const data = Object.assign({}, searchFilter, { stoppedTime : moment(dateString).valueOf()})
    setSearchFilter(data)
  }
  function searchClick(){
    props.getAllTasks(page,pageSize,searchFilter)
    setLoading(true)
  }

  // 刷新相关
  function refreshClick(){
    if (Object.keys(props.userCheckToken).length !== 0){
      if(props.userCheckToken.roles['zukvnc-test'] === 'ADMIN') {
        props.getAllTasks(page, pageSize, {})
        setSearchFilter({})
      } else {
        props.getAllTasks(page, pageSize, { userId : localStorage.getItem('user_id')})
      }
    }
  }
  function handleChange(e) {
  }
  function onChange(value, dateString) {
  }
  //新建任务
  function showTaskCreate() {
    setTaskCreate(true)
  }
  function urlChange(e){
    setUrl(e.target.value)
  }
  function callbackChange(e){
    setCallback(e.target.value)
  }
  function regionChange(value){
    setRegion(value)
  }
  function taskCreateOk(e) {
    props.postNewTasks(url, callback, region)
    setTaskCreate(false)
  };

  function taskCreateCancel(e) {
    setTaskCreate(false)
  };

  //停止任务
  function stopTask(){
    props.stopTasks(rowID)
    
  }
  function rowRecord(record){
    return {
      onClick: () => {rowClick(record)}, // 点击行
    };
  }
  function rowClick(record){
    if(record.recordFiles.length !== 0){
      setRecordNameDownload(record.recordFiles[0].recordName)
    }
    if(record.rtmpUrls.length !==0){
      setRtmpID(record.rtmpUrls[0].id)
    }
    setRowID(record.id)
  }

  //新建录制
  function recordNameChange(e){
    setRecordName(e.target.value)
  }
  function recordFormatChange(e){
    setRecordFormat(e.target.value)
  }
  function audioSelectChange(value){
    setIsAudio(value)
  }
  function showRecordCreate() {
    setRecordCreate(true)
  }
  function recordCreateOk(e) {
    props.postNewRecord(rowID, recordName, recordFormat, isAudio)
    setRecordCreate(false)
  };

  function recordCreateCancel(e) {
    setRecordCreate(false)
  };

  //停止录制
  function stopRecordClick(){
    props.stopRecord(rowID)
  }
  function onOk(value) {
  }
  
  
  //新建推流
  function showFlowCreate() {
    setFlowCreate(true)
  }
  function flowChange(e){
    setRtmpUrl(e.target.value)
  }
  function flowCreateOk() {
    props.postNewRTMP(rowID, rtmpUrl)
    setFlowCreate(false)
  };
  function stopFlowClick() {
    props.stopRTMP(rowID,rtmpID)
  }
  function flowCreateCancel() {
    setFlowCreate(false)
  };

  //录像下载相关
  function downLoad() {
    props.postRecordUrl(rowID, recordNameDownload, 604800)
  }
  function deleteClick() {
  }
  const columns = [
    {
      title: '任务编号',
      dataIndex: 'id',
    },
    {
      title: '发起人',
      dataIndex: 'userId',
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
    },
    {
      title: '任务状态',
      dataIndex: 'stopTime',
      render: (text, record) => {
        return (
          record.ended ?
            record.startTime == 0 ?
              <span>已取消</span>
              :
              <span>已结束</span>
            :
            record.startTime == 0 ?
              <span>发起中</span>
              :
              <Popconfirm title="停止任务?" onConfirm={stopTask}>
                <Tooltip title="停止任务">
                  <span>
                    <a>进行中</a>
                  </span>
                </Tooltip>
              </Popconfirm>
        )
      }
    },
    {
      title: '持续时间(分钟)',
      dataIndex: 'totalTime',
    },
    {
      title: '录制',
      dataIndex: '录制',
      render: (text,record) => {
        return (
          record.ended ?
            <span>已结束</span>
            :
            record.startTime == 0 ?
              <span>任务发起中</span>
              :
              record.recordingName == null ?
                record.recordFiles.length === 0 ?
                  <div>
                    <Tooltip title="新建录制">
                      <a onClick={showRecordCreate}>未录制</a>
                    </Tooltip>
                  </div>
                  :
                  <span>已结束</span>
                :
                <div>
                  <Popconfirm title="停止录制?" onConfirm={stopRecordClick}>
                    <Tooltip title="停止录制">
                      <a>录制进行中</a>
                    </Tooltip>
                  </Popconfirm>
                </div>
        )
      }
    },
    {
      title: '推流',
      dataIndex: '推流',
      render: (text, record) => {
        return (
          record.ended ?
            <span>已结束</span>
            :
            record.startTime == 0 ?
              <span>任务发起中</span>
              :
              record.rtmpUrls.length == 0 ?
                <div>
                    <Tooltip title="新建推流">
                      <a onClick={showFlowCreate}>未推流</a>
                    </Tooltip>
                </div>
                :
                <Popconfirm title="停止推流?" onConfirm={stopFlowClick}>
                  <Tooltip title="停止推流">
                    <a>已推流</a>
                  </Tooltip>
                </Popconfirm>
                
        )
      }
    },
    {
      title: '录像',
      dataIndex: '录像',
      render: (text, record) => {
        return (
          record.recordFiles.length == 0 ?
            <span>无</span>
            :
            <div>
              <Popconfirm title="下载?" onConfirm={downLoad}>
              <a>下载</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Popconfirm title="删除?" onConfirm={deleteClick}>
                <a>删除</a>
              </Popconfirm>
            </div>
        )
      }
    },
  ];
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SiderPage /> */}
      <Layout>
        <HeaderPage history={props.history} style={{ background: '#fff', padding: 0 }} />
        <Content className="content">
          {
            loading ?
              <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
                <LoadingPage />
              </div>
              :
              <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
                <h2>任务</h2>
                <Row type="flex" justify="start">
                  <Col className="content-filter">
                    <span className="task">任务状态: </span>
                    <Select style={{ width: 90 }} onChange={taskSearchChange}>
                      <Option key={false}>未结束</Option>
                      <Option key={true}>已结束</Option>
                    </Select>
                  </Col>

                  {/* <Col className="content-filter">
                    <span className="task">推流状态: </span>
                    <Select defaultValue="未结束" style={{ width: 90 }} onChange={handleChange}>
                      <Option value="未结束">未推流</Option>
                      <Option value="已结束">已推流</Option>
                    </Select>
                  </Col>

                  <Col className="content-filter">
                    <span className="task">录制状态: </span>
                    <Select defaultValue="未结束" style={{ width: 90 }} onChange={handleChange}>
                      <Option value="未结束">未录制</Option>
                      <Option value="已结束">已录制</Option>
                    </Select>
                  </Col> */}

                  <Col className="content-filter">
                    <span className="task">开始时间: </span>
                    <DatePicker showTime placeholder="Select Time" onChange={startTimeChange} onOk={onOk} />
                  </Col>

                  <Col className="content-filter">
                    <span className="task">结束时间: </span>
                    <DatePicker showTime placeholder="Select Time" onChange={stopTimeChange} onOk={onOk} />
                  </Col>

                  <Col className="content-filter">
                    <Button type="primary" shape="round" icon="search" onClick={searchClick}>搜索</Button>
                  </Col>
                  <Col className="content-filter">
                    <Button type="primary" shape="round" icon="search" onClick={refreshClick}>刷新</Button>
                  </Col>
                  <Col className="content-filter">
                    <Button type="primary" shape="round" icon="plus" onClick={showTaskCreate}>新建任务</Button>
                  </Col>
                </Row>
                <Table className="table-info" columns={columns} dataSource={tableData} pagination={false} bordered onRow={rowRecord}/>
                <Pagination style={{ marginBottom: 10, marginTop: 10}} defaultCurrent={1} total={totalCounts} pageSize={pageSize} onChange={changePage}/>
              </div>   
          }
          
          {/* 新建任务对话框 */}
          <Modal
            title="新建任务"
            visible={taskCreate}
            onOk={taskCreateOk}
            onCancel={taskCreateCancel}
          >
            <Row className="content">
              <Col span={4}>
              <span>网页网址:</span>
              </Col>
              <Col span={20}>
                <Input
                  className="input"
                  onChange={urlChange}
                  />
              </Col>
            </Row>

            <Row className="content">
              <Col span={4}>
              <span>回调网址:</span>
              </Col>
              <Col span={20}>
                <Input
                  className="input"
                  onChange={callbackChange}
                  />
              </Col>
            </Row>

            <Row className="content">
              <Col span={4}>
              <span>Region:</span>
              </Col>
              <Col span={20}>
                <Select
                  placeholder="请选择地点..."
                  style={{ width: 300, marginBottom: 10 }}
                  onChange={regionChange}
                >
                  <Select.Option key="cn-north-1">cn-north-1</Select.Option>
                  <Select.Option key="us-east-1">us-east-1</Select.Option>
                </Select>
              </Col>
            </Row>
          </Modal>

          {/* 新建录制对话框 */}
          <Modal
            title="新建录制"
            visible={recordCreate}
            onOk={recordCreateOk}
            onCancel={recordCreateCancel}
          >
            <Row className="content">
              <Col span={6}>
              <span>录像名称:</span>
              </Col>
              <Col span={18}>
                <Input
                  className="input"
                  onChange={recordNameChange}
                  />
              </Col>
            </Row>

            <Row className="content">
              <Col span={6}>
              <span>录像格式:</span>
              </Col>
              <Col span={18}>
                <Input
                  className="input"
                  onChange={recordFormatChange}
                  />
              </Col>
            </Row>

            <Row className="content">
              <Col span={6}>
              <span>是否只录制音频:</span>
              </Col>
              <Col span={18}>
                <Select style={{ width: 90 }} onChange={audioSelectChange}>
                  <Option key={true}>是</Option>
                  <Option key={false}>否</Option>
                </Select>
              </Col>
            </Row>
          </Modal>

          {/* 新建推流对话框 */}
          <Modal
            title="新建推流"
            visible={flowCreate}
            onOk={flowCreateOk}
            onCancel={flowCreateCancel}
          >
            <Row className="content">
              <Col span={4}>
              <span>推流地址:</span>
              </Col>
              <Col span={20}>
                <Input
                  className="input"
                  onChange={flowChange}
                  />
              </Col>
            </Row>
          </Modal>
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    userCheckToken: state.userReducer.userCheckToken,
    taskOnGoing: state.taskReducer.taskOnGoing,
    allTaskInfo: state.taskReducer.allTaskInfo,
    newTaskInfo: state.taskReducer.newTaskInfo,
    stopTaskInfo: state.taskReducer.stopTaskInfo,
    newRecordInfo: state.taskReducer.newRecordInfo,
    stopRecordInfo: state.taskReducer.stopRecordInfo,
    newRtmpInfo: state.taskReducer.newRtmpInfo,
    stopRtmpInfo: state.taskReducer.stopRtmpInfo,
    postRecordUrlInfo: state.taskReducer.postRecordUrlInfo,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    postLogin: bindActionCreators(TaskActionCreators.GetCheckTokenAction, dispatch),
    getAllTasks: bindActionCreators(TaskActionCreators.GetAllTasksAction, dispatch),
    postNewTasks: bindActionCreators(TaskActionCreators.PostNewTasksAction, dispatch),
    stopTasks: bindActionCreators(TaskActionCreators.StopTasksAction, dispatch),
    postNewRecord: bindActionCreators(TaskActionCreators.PostNewRecordAction, dispatch),
    stopRecord: bindActionCreators(TaskActionCreators.StopRecordAction, dispatch),
    postNewRTMP: bindActionCreators(TaskActionCreators.PostNewRTMPAction, dispatch),
    stopRTMP: bindActionCreators(TaskActionCreators.StopRTMPAction, dispatch),
    postRecordUrl: bindActionCreators(TaskActionCreators.PostRecordUrlAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(Task)