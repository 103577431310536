import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import 'antd/dist/antd.css';
import './ForgotPassword.css';
import { Input, Row, Col, message, Modal} from 'antd';
import { UserActionCreators } from '../../action/userAction'

function ForgotPassword(props) {
  //忘记密码相关
  const [userID, setUserID] = useState('')
  const [resetCode, setResetCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  //请求token接口
  useEffect(() => {
    const urlInfo = props.location.search.substr(1)
    setUserID(urlInfo.split('&')[0].split('=')[1])
    setResetCode(urlInfo.split('&')[1].split('=')[1])
  },[])

  useEffect(() => {
    if(props.userResetInfo === 'sucsseful'){
      props.history.push('/login')
    }
  },[props.userResetInfo])

  function passwordChange(e){
    setNewPassword(e.target.value)
  }
  function resetPasswordOk(){
    if(!newPassword){
      message.info('密码输入不能为空！')
      return
    }
    props.resetPassword(userID, newPassword, resetCode)
  }
  function resetPasswordCancel(){
    props.history.push('/login')
  }
  return (
    <div className="resetContent">
      <Modal
        title="输入新密码并重置"
        visible={true}
        onOk={resetPasswordOk}
        onCancel={resetPasswordCancel}
      >
        <Row className="content">
          <Col span={4}>
            <span>UserID:</span>
          </Col>
          <Col span={20}>
            <Input value={userID} disabled></Input>
          </Col>
        </Row>
        <Row className="content">
          <Col span={4}>
            <span>ResetCode:</span>
          </Col>
          <Col span={20}>
            <Input value={resetCode} disabled></Input>
          </Col>
        </Row>
        <Row className="content">
          <Col span={4}>
            <span>Password:</span>
          </Col>
          <Col span={20}>
            <Input type="password" onChange={passwordChange}></Input>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userResetInfo: state.userReducer.userResetInfo,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    resetPassword: bindActionCreators(UserActionCreators.ResetPasswordAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(ForgotPassword);