import React, { useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './Header.css';
import 'antd/dist/antd.css';
import { Layout, Row, Col,Popover, Icon } from 'antd';
import { UserActionCreators } from '../../action/userAction'

const { Header } = Layout;

function HeaderPage(props) {
  const title = <div>
                <p>个人中心</p>
                <p>个人设置</p>
              </div>;
  const [userCheckToken,setUserCheckToken] = useState('')
  const tokenRef = useRef()

  const logOutClick = () => {
    localStorage.removeItem('user_token')
    props.history.push('/login')
  }
  
  return (
    <div>
      <Header style={{ background: '#fff', padding: 0 }}>
        <Row>
          <Col>
            <Popover placement="bottom" title={title} content={<p onClick={logOutClick} >退出</p>} >
              <div className="user">
                <Icon type="user" className="userIcon"/>
                <span className="userName">{props.userCheckToken.username}</span>
                {
                  props.userCheckToken.roles && Object.keys(props.userCheckToken.roles).length !== 0 ?
                    <span>({props.userCheckToken.roles['zukvnc-test']})</span>
                    :
                    null
                }
              </div>
            </Popover>
          </Col>
        </Row>
      </Header>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userInfo,
    userCheckToken: state.userReducer.userCheckToken,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    getCheckToken: bindActionCreators(UserActionCreators.GetCheckTokenAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(HeaderPage);