import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'
export const InfoActionTypes = {
  GET_USER_INFO:'GET_USER_INFO',
  GET_ALL_USER_INFO:'GET_ALL_USER_INFO',
}
export const InfoActionCreators = {
  GetUserInfoAction(id, periods){
    return async (dispatch) => {
      try {
        const params = {
          userId: id,
          periods: periods
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })}
        })
        const result = await instance.get('/zukvnc-test/api/v1/users/' + id + '/statistics', { params })
          if (result.status === 200) {
            dispatch({type: InfoActionTypes.GET_USER_INFO, payload: result.data})
          }
      } catch (e) {
        console.error(e.response)
      }
    }
  },
  GetAllInfoAction(periods){
    return async (dispatch) => {
      try {
        const params = {
          periods: periods
        }
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })}
        })
        const result = await instance.get('/zukvnc-test/api/v1/users/statistics', { params })
          if (result.status === 200) {
            dispatch({type: InfoActionTypes.GET_ALL_USER_INFO, payload: result.data})
          }
      } catch (e) {
        console.error(e.response)
      }
    }
  },
}