import axios from 'axios'
import { message } from 'antd'
export const InstanceActionTypes = {
  GET_INSTANCE:'GET_INSTANCE',
}
export const InstanceActionCreators = {
  GetInstanceAction(){
    return async (dispatch) => {
      try {
        const instance = axios.create({
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('user_token') },
        })
        const result = await instance.get('/zukvnc-test/api/v1/instances/')
          if (result.status === 200) {
            dispatch({type: InstanceActionTypes.GET_INSTANCE, payload: result.data})
          }
      } catch (e) {
        console.error(e.response)
      }
    }
  },
}