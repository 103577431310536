import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './Login.css';
import 'antd/dist/antd.css';
import { Input, Icon, Row, Col, Button, message, Spin, Tabs, Layout, Modal} from 'antd';
import axios from 'axios';
import { UserActionCreators } from '../../action/userAction'
const { TabPane } = Tabs
const { Content, Header } = Layout

function Login(props) {
  //忘记密码相关
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [isForgotPasswordModal, setIsForgotPasswordModal] = useState(false)

  //login相关
  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')

  //注册相关
  const [usernameRegister,setUsernameRegister] = useState('')
  const [passwordRegister,setPasswordRegister] = useState('')
  const [emailRegister,setEmailRegister] = useState('')
  const [loading,setLoading] = useState(false)

  //验证码相关
  const [authCode,setAuthCode] = useState('')
  const [modalEmail,setModalEmail] = useState('')
  const [userID,setUserID] = useState('')
  const [showAuthcodeForm,setShowAuthcodeForm] = useState(false)
  const [loginForm,setLoginForm] = useState(false)
  const [activeKey,setActiveKey] = useState('1')
  const usernameRegex = new RegExp('^[a-zA-Z]|^[a-zA-Z]((?=.*[0-9])|(?=.*[_])).{5,30}')
  const passwordRegex = new RegExp('(?=.*[0-9])(?=.*([a-zA-Z]|[^a-zA-Z0-9])).{8,30}')
  const emailRegex = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$")
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const userInfoRef = useRef()
  const userActivationRef = useRef()

  
  //请求token接口
  useEffect(() => {
    userInfoRef.current = props.userInfo
    userActivationRef.current = props.userActivation
    const userToken = localStorage.getItem('user_token')
    if(userToken == null){
      return
    }
    if(userToken){
      props.getLoginCheckToken('Bearer ' + userToken)
    }
  },[])

  //请求token接口后检查token是否正确 已登陆的用户直接跳转home
  useEffect(() => {
    if(props.userLoginCheckToken.username){
      props.history.push('/home')
    }
  },[props.userLoginCheckToken])

  //点击login按钮后获取登录信息
  useEffect(() => {
    if(userInfoRef.current !== props.userInfo) {
      if(props.userInfo == 'error'){
        setLoading(false)
        return
      }
      if(props.userInfo.token && props.userInfo.user.activated){
        setLoading(false)
        localStorage.setItem('user_token',props.userInfo.token)
        localStorage.setItem('user_id',props.userInfo.user.id)
        props.history.push('/home')
      } else {
        setLoading(false)
        localStorage.setItem('user_token',props.userInfo.token)
        setModalEmail(props.userInfo.user.email)
        setUserID(props.userInfo.user.id)
        setShowAuthcodeForm(true)
      }
    }
  },[props.userInfo])

  //点击激活码按钮后的激活码状态判断
  useEffect(() => {
    if(userActivationRef.current !== props.userActivation) {
      if(props.userActivation == 'error'){
        setLoading(false)
        props.history.push('/home')
      } else {
        setLoading(false)
        setShowAuthcodeForm(false)
        props.history.push('/home')
      }
    }
  },[props.userActivation])

  const usernameChange = (e) => {
    setUsername(e.target.value)
  }
  const passwordChange = (e) => {
    setPassword(e.target.value)
  }
  const usernameRegisterChange = (e) => {
    setUsernameRegister(e.target.value)
  }
  const passwordRegisterChange = (e) => {
    setPasswordRegister(e.target.value)
  }
  const emailRegisterChange = (e) => {
    setEmailRegister(e.target.value)
  }
  const authCodeChange = (e) => {
    setAuthCode(e.target.value)
  }
  const authcodeFormOk = (e) => {
    if(!authCode){
      message.error('Auth Code is required!')
      return
    }
    props.updateUserActivationCode(userID,authCode)
    setLoading(true)
  };

  const authcodeFormCancel = (e) => {
    setShowAuthcodeForm(false)
  };
  const loginFormOk = () => {
    setLoginForm(false)
  }
  const loginFormCancel = () => {
    setLoginForm(false)
  }
  const registerClick = () => {
    setLoginForm(true)
    setActiveKey('1')
  }
  const loginClick = () => {
    setLoginForm(true)
    setActiveKey('2')
  }
  function changeActiveKey(key) {
    setActiveKey(key)
  }
  const register = () => {
    
    if(!usernameRegister || !passwordRegister || !emailRegister){
      message.error('账号或密码或邮箱不能为空!')
      return
    }
    // 用户名要求 最少6位 字母开头 只能包含字母 数字 下划线
    if(!usernameRegex.test(usernameRegister)){
      message.error('注册用户名复杂度需满足:字母开头,长度至少6位,最多30位,需同时含有字母,数字和下划线.')
      return
    }
    
    if(!passwordRegex.test(passwordRegister)){
      message.error('注册密码复杂度需满足:密码长度至少8位,最多30位,需同时含有数字,字母或特殊字符.')
      return
    }
    if(!emailRegex.test(emailRegister)){
      message.error('注册邮箱格式不符.')
      return
    }
    props.postRegister(usernameRegister, passwordRegister,emailRegister)
  }
  const login = () => {
    if(!username || !password){
      message.error('账号或密码不能为空!')
      return
    }
    if(!usernameRegex.test(username)){
      message.error('用户名格式错误！')
      return
    }
    
    if(!passwordRegex.test(password)){
      message.error('密码格式错误!')
      return
    }
    props.postLogin(username,password)
    setLoading(true)
    setLoginForm(false)
  }
  
  if(loading){
    return (
      <div className="loginLoading">
        <Spin indicator={antIcon} />
        <h1>Loading...</h1>
      </div>
    )
  }

  //忘记密码相关
  const forgotPasswordClick = () => {
    setIsForgotPasswordModal(true)
  }
  const forgotPasswordOk = () => {
    if(!forgotPasswordEmail){
      message.info('邮箱不能为空!')
    }
    if(!emailRegex.test(forgotPasswordEmail)){
      message.error('邮箱格式不符.')
      return
    }
    if(forgotPasswordEmail){
      props.postForgotPasswordEmail(forgotPasswordEmail)
    }
    setIsForgotPasswordModal(true)
  }
  const forgotPasswordCancel = () => {
    setIsForgotPasswordModal(false)
  }
  const forgotInputChange = (e) => {
    setForgotPasswordEmail(e.target.value)
  }
  return (
    <div className="login">
      <div className="headerTop">
        <span className="logoText">ZukVNC</span>
        <Button className="buttonSign" type="primary" shape="round" onClick={registerClick}>注册</Button>
        <Button className="buttonLogin" type="primary" shape="round" onClick={loginClick}>登录</Button>
      </div>
      
      <Modal
        visible={loginForm}
        onOk={loginFormOk}
        onCancel={loginFormCancel}
      >
        <Tabs activeKey={activeKey} onChange={changeActiveKey}>
          <TabPane tab="注册" key="1">
            <Input
              placeholder="Enter your username"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="usernameRegister"
              onChange={usernameRegisterChange}
              className="input"
            />
            <Input
              placeholder="Enter your password"
              type="password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="passwordRegister"
              onChange={passwordRegisterChange}
              className="input"
            />
            <Input
              placeholder="Enter your email"
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="emailRegister"
              onChange={emailRegisterChange}
              className="input"
            />
            <Button type="primary" block onClick={register}>
              注册
            </Button>
          </TabPane>
                
          <TabPane tab="登录" key="2">
            <Input
              placeholder="Enter your username"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="username"
              onChange={usernameChange}
              className="input"
              value={username}
            />
            <Input
              placeholder="Enter your password"
              type="password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="password"
              onChange={passwordChange}
              className="input"
              value={password}
            />
            <a onClick={forgotPasswordClick}>忘记密码</a>
            <Button type="primary" block onClick={login} style={{marginTop: 10}}>
              登录
            </Button>
          </TabPane>
        </Tabs>
      </Modal>
      

      <Modal
        title="We have send Auth Code to your email"
        visible={showAuthcodeForm}
        onOk={authcodeFormOk}
        onCancel={authcodeFormCancel}
      >
        <Row className="content">
          <Col span={4}>
            <span>Email:</span>
          </Col>
          <Col span={20}>
            <Input
              className="input"
              value={modalEmail}
              disabled
              />
          </Col>
        </Row>
        <Row className="content">
          <Col span={4}>
          <span>Code:</span>
          </Col>
          <Col span={20}>
            <Input
              className="input"
              name="authCode"
              onChange={authCodeChange}
              />
          </Col>
        </Row>  
      </Modal>

      
      <Modal
        title="请输入接收重置密码链接的邮箱"
        visible={isForgotPasswordModal}
        onOk={forgotPasswordOk}
        onCancel={forgotPasswordCancel}
      >
        <Row className="content">
          <Col span={4}>
            <span>Email:</span>
          </Col>
          <Col span={20}>
            <Input
              className="input"
              onChange={forgotInputChange}
              />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userInfo,
    userRegisterInfo: state.userReducer.userRegister,
    userLoginCheckToken: state.userReducer.userLoginCheckToken,
    userActivation: state.userReducer.userActivation,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    postLogin: bindActionCreators(UserActionCreators.PostLoginAction, dispatch),
    postRegister: bindActionCreators(UserActionCreators.PostRegisterAction, dispatch),
    getLoginCheckToken: bindActionCreators(UserActionCreators.GetLoginCheckTokenAction, dispatch),
    updateUserActivationCode: bindActionCreators(UserActionCreators.PostUserActivationCodeAction, dispatch),
    postForgotPasswordEmail: bindActionCreators(UserActionCreators.PostForgotPasswordEmailAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(Login);