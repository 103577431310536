import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { InfoActionCreators } from '../../action/infoAction'
import 'antd/dist/antd.css';
import './InfoPage.css';
import { Layout, Row, Col, Card, Button, DatePicker, Select, message } from 'antd';
import moment from 'moment'
import echarts from 'echarts'
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
const { Content} = Layout;
const { Option } = Select;

function InfoPage(props) {
  const [ userContent, setUserContent ] = useState([])
  const [ startTime, setStartTime ] = useState('')
  const [ endTime, setEndTime ] = useState('')
  const [ timeSelect, setTimeSelect ] = useState([])
  const [ optionSelect, setOptionSelect ] = useState('')
  const userInfoRef = useRef()

  useEffect(()=>{
    userInfoRef.current = props.userInfo
    const userID = localStorage.getItem('user_id')
    let today = parseInt(moment().startOf('day').format('x'))
    const timeSelect= [today]
    for (let i=0;i<30;i++) {
      today = today - 24*60*60*1000
      timeSelect.push(today)
    }
    setTimeSelect(timeSelect.reverse())
    if (Object.keys(props.userCheckToken).length !== 0){
      if (props.userCheckToken == 'error') {
        props.history.push('/login')
        return
      }
      if(props.userCheckToken.roles['zukvnc-test'] === 'ADMIN') {
        props.getAllInfo(timeSelect)
      } else {
        props.getUserInfo(userID, timeSelect)
      }
    }
  },[props.userCheckToken])

  useEffect(()=>{
    const xData = []
    timeSelect.slice(0,-1).map((item,index)=>{
      return (
        xData.push(moment(item).format('YYYY-MM-DD'))
      )
    })
    const series = []
    const task = {
      name: '任务数量',
      type: 'line',
      // stack: '总量',
      data: []
    }
    const record = {
      name: '录制数量',
      type: 'line',
      // stack: '总量',
      data: []
    }
    const totalTaskTime = {
      name: '总任务时间(小时)',
      type: 'line',
      // stack: '总量',
      data: []
    }
    if (props.allUserInfo.length !== 0) {
      props.allUserInfo.map((item,index)=>{
        return (
          task.data.push(item.taskNum),
          record.data.push(item.recordNum),
          totalTaskTime.data.push((item.totalTaskTimes/1000/60/60).toFixed(2))
        )
      })
      series.push(task,record,totalTaskTime)
    }
    monthRender(xData,series)
  },[props.allUserInfo])
  
  useEffect(()=>{
    const xData = []
    timeSelect.slice(0,-1).map((item,index)=>{
      return (
        xData.push(moment(item).format('YYYY-MM-DD'))
      )
    })
    const series = []
    const task = {
      name: '任务数量',
      type: 'line',
      // stack: '总量',
      data: []
    }
    const record = {
      name: '录制数量',
      type: 'line',
      // stack: '总量',
      data: []
    }
    const totalTaskTime = {
      name: '总任务时间(小时)',
      type: 'line',
      // stack: '总量',
      data: []
    }
    if (props.userInfo.length !== 0) {
      props.userInfo.map((item,index)=>{
        return (
          task.data.push(item.taskNum),
          record.data.push(item.recordNum),
          totalTaskTime.data.push((item.totalTaskTimes/1000/60/60).toFixed(2))
        )
      })
      series.push(task,record,totalTaskTime)
    }
    monthRender(xData,series)
  },[props.userInfo])

  function monthRender(xData,series){
    const monthChart = echarts.init(document.getElementById('monthRender'), 'default')
    const option = {
      title: {
          text: '折线图'
      },
      tooltip: {
          trigger: 'axis'
      },
      legend: {
          data: ['任务数量', '录制数量', '总任务时间(小时)']
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      toolbox: {
          feature: {
              saveAsImage: {}
          }
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData
      },
      yAxis: {
          type: 'value'
      },
      series: series
    }
    monthChart.setOption(option)
  }

  function optionChange(value) {
    setOptionSelect(value)
  }
  function startTimeChange(value, dateString) {
    setStartTime(value)
  }
  function stopTimeChange(value, dateString) {
    setEndTime(value)
  }
  //搜索相关
  function searchClick(){
    if (!optionSelect) {
      message.error('周期不能为空!')
      return
    }
    const userID = localStorage.getItem('user_id')
    const isAdmin = Object.keys(props.userCheckToken).length !== 0 && props.userCheckToken.roles['zukvnc-test'] === 'ADMIN' ? true : false
    let start = startTime.format('YYYY-MM-DD') + ' 00:01:00'
    start = parseInt(moment(start).format('x')) 
    const arr= [start]
    if (optionSelect == 'day' && startTime < endTime && startTime && endTime) {
      const daysDiff = endTime.diff(startTime, 'days')
      for (let i=0;i < daysDiff;i++) {
        start = start + 24*60*60*1000
        arr.push(start)
      }
      setTimeSelect(arr)
      isAdmin ? props.getAllInfo(arr) : props.getUserInfo(userID, arr)
    } else if (optionSelect == 'week' && startTime < endTime && startTime && endTime) {
      const weekDiff = endTime.diff(startTime, 'weeks')
      if (weekDiff == 0) {
        message.info('日期不能小于一周!')
        return
      } else {
        for (let i=0;i < weekDiff;i++) {
          start = start + 7*24*60*60*1000
          arr.push(start)
        }
        setTimeSelect(arr)
        isAdmin ? props.getAllInfo(arr) : props.getUserInfo(userID, arr)
      }
    } else if (optionSelect == 'month' && startTime < endTime && startTime && endTime) {
      const monthDiff = endTime.diff(startTime, 'months')
      if (monthDiff == 0) {
        message.info('日期不能小于一个月!')
        return
      } else {
        for (let i=0;i < monthDiff;i++) {
          start = start + 31*24*60*60*1000
          arr.push(start)
        }
        setTimeSelect(arr)
        isAdmin ? props.getAllInfo(arr) : props.getUserInfo(userID, arr)
      }
    } else {
      message.error('日期格式不正确!')
      return
    }
    
  }
  function clearClick(){
    setOptionSelect('')
    setStartTime('')
    setEndTime('')
    let today = parseInt(moment().startOf('day').format('x'))
    const timeSelect= [today]
    for (let i=0;i<30;i++) {
      today = today - 24*60*60*1000
      timeSelect.push(today)
    }
    setTimeSelect(timeSelect.reverse())
    const userID = localStorage.getItem('user_id')
    if(Object.keys(props.userCheckToken).length !== 0 && props.userCheckToken.roles['zukvnc-test'] === 'ADMIN') {
      props.getAllInfo(timeSelect)
    } else {
      props.getUserInfo(userID, timeSelect)
    }
    
  }
  function handleChange(e) {
  }
  function onChange(value, dateString) {
  }
  function onOk(value) {
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SiderPage /> */}
      <Layout>
        <HeaderPage style={{ background: '#fff', padding: 0 }} history={props.history} />
        <Content className="content">
          <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
            <Row>
              <Col>
                <Card title="数据统计">
                  <Row type="flex" justify="start">
                    <Col className="content-filter">
                      <span className="task">周期: </span>
                      <Select style={{ width: 90 }} onChange={optionChange} value={optionSelect}>
                        <Option key={'day'}>天</Option>
                        <Option key={'week'}>周</Option>
                        <Option key={'month'}>月</Option>
                      </Select>
                    </Col>

                    <Col className="content-filter">
                      <span className="task">开始时间: </span>
                      <DatePicker placeholder="Select Time" onChange={startTimeChange} onOk={onOk} value={startTime} />
                    </Col>

                    <Col className="content-filter">
                      <span className="task">结束时间: </span>
                      <DatePicker placeholder="Select Time" onChange={stopTimeChange} onOk={onOk} value={endTime} />
                    </Col>

                    <Col className="content-filter">
                      <Button type="primary" shape="round" icon="search" onClick={searchClick}>搜索</Button>
                    </Col>
                    <Col className="content-filter">
                      <Button type="primary" shape="round" icon="search" onClick={clearClick}>清除</Button>
                    </Col>
                  </Row>
                  <div id="monthRender" style={{width: '100%', height: '500%',marginTop: '20px'}} />
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    userCheckToken: state.userReducer.userCheckToken,
    userInfo: state.infoReducer.getUserInfo,
    allUserInfo: state.infoReducer.getAllUserInfo,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    getUserInfo: bindActionCreators(InfoActionCreators.GetUserInfoAction, dispatch),
    getAllInfo: bindActionCreators(InfoActionCreators.GetAllInfoAction, dispatch),
  }
}
export default connect(mapStateToProps, mapDsipatchToProps)(InfoPage)


