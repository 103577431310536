import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './FormPage.css';
import 'antd/dist/antd.css';
import { FormActionCreators } from '../../action/formAction'
import { Layout, Menu, Select, Table, Row, Col, DatePicker, Button, Popconfirm, List, Modal, Avatar, Input, message, Pagination } from 'antd';
import HeaderPage from '../../components/Header/Header'
import FooterPage from '../../components/Footer/Footer'
import LoadingPage from '../../components/Loading/Loading'
import moment from 'moment';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;

function FormPage(props) {
  const [ loading, setLoading ] = useState(true)
  const [ status1, setStatus1 ] = useState(true)
  const [ isAdmin, setIsAdmin] = useState(false)
  const [ isFormDetail, setIsFormDetail] = useState(false)
  const [ isCreateTicket, setIsCreateTicket ] = useState(false)

  // 工单信息
  const [ tickets, setTickets ] = useState([])
  const [ ticketID, setTicketID ] = useState('')
  const [ ticketClosed, setTicketClosed ] = useState(false)
  const [ totalCounts, setTotalCounts ] = useState(1)
  const [ pageSize, setPageSize ] = useState(20)

  // 工单搜索
  const [ ticketStatus, setTicketStatus ] = useState(false)
  const [ startTime, setStartTime ] = useState(false)
  const [ endTime, setEndTime ] = useState(false)

  // 详情页面回复信息
  const [ posts, setPosts ] = useState([])

  // 新建相关
  const [ title, setTitle ] = useState('')
  const [ content, setContent ] = useState('')
  const [ confirmLoading, setConfirmLoading ] = useState(false)

  //回复相关
  const [ replyContent, setReplyContent ] = useState('')
  const createRef = useRef()
  const replyRef = useRef()
  const closeRef = useRef()

  useEffect(()=>{
    createRef.current = props.newTicketInfo
    replyRef.current = props.newReplyInfo
    closeRef.current = props.closeTicketInfo
  },[])

  useEffect(() => {
    if (Object.keys(props.userCheckToken).length !== 0) {
      if (props.userCheckToken == 'error') {
      props.history.push('/login')
      return
      }
      if(props.userCheckToken.roles['gateway'] === 'ADMIN') {
        setIsAdmin(true)
        props.getTicket(0, 20)
      } else {
        setIsAdmin(false)
        props.getTicket(0, 20, localStorage.getItem('user_id'))
      }
    }
  },[props.userCheckToken])

  useEffect(() => {
    if (Object.keys(props.getTicketInfo).length !== 0) {
      setTickets(props.getTicketInfo.tickets)
      setTotalCounts(props.getTicketInfo.totalCount)
      setLoading(false)
    }
  },[props.getTicketInfo])

  useEffect(() => {
    if (createRef.current !== props.newTicketInfo) {
      if (Object.keys(props.newTicketInfo).length !== 0) {
        message.info('创建成功！')
        setIsCreateTicket(false)
        setConfirmLoading(false)
        setTitle('')
        setContent('')
        props.getTicket(0, 20, localStorage.getItem('user_id'))
      }
    }
  },[props.newTicketInfo])

  useEffect(() => {
    if (replyRef.current !== props.newReplyInfo) {
      if (Object.keys(props.newReplyInfo).length !== 0) {
        message.info('回复成功！')
        setIsFormDetail(false)
        setReplyContent('')
        if (isAdmin) {
          props.getTicket(0, 20)
        } else {
          props.getTicket(0, 20, localStorage.getItem('user_id'))
        }
      }
    }
  },[props.newReplyInfo])

  useEffect(() => {
    if (closeRef.current !== props.closeTicketInfo) {
      if (Object.keys(props.closeTicketInfo).length !== 0) {
        if (props.userCheckToken.roles['gateway'] === 'ADMIN') {
          message.info('关闭成功！')
          props.getTicket(0, 20)
        } else {
          message.info('关闭成功！')
          props.getTicket(0, 20, localStorage.getItem('user_id'))
        }
      }
    }
    
  },[props.closeTicketInfo])

  // 新建工单
  function newTicket() {
    setIsCreateTicket(true)
  }
  function newTicketOk() {
    if (title.length === 0 || content.length === 0) {
      message.info('输入不能为空!')
      return
    }
    setConfirmLoading(true)
    props.postTicket(title, content)
  }
  function newTicketCancel() {
    setIsCreateTicket(false)
  }
  function titleChange(e) {
    setTitle(e.target.value)
  }
  function contentChange(e) {
    setContent(e.target.value)
  }
  
  // row click
  function rowRecord(record){
    return {
      onClick: () => {rowClick(record)},
    };
  }
  function rowClick(record){
    setTicketID(record.id)
    setTicketClosed(record.closed)
  }

  // 关闭ticket
  function closeTicket() {
    props.closeTicket(ticketID)
  }

  // 工单详情
  function detailTicketClick(posts) {
    setIsFormDetail(true)
    setPosts(posts)
  }

  // 搜索工单
  function handleChange(value) {
    setTicketStatus(value)
  }
  function startTimeChange(value, dateString) {
    setStartTime(value)
  }
  function endTimeChange(value, dateString) {
    setEndTime(value)
  }
  function ticketSearch() {
    const start = parseInt(moment(startTime).format('x'))
    const end = parseInt(moment(endTime).format('x'))
    if (isAdmin) {
      props.searchTicket(start,end)
    } else {
      props.searchTicket(start,start,localStorage.getItem('user_id'))
    }
  }

  // 提交回复
  function handleReplyChange(e){
    setReplyContent(e.target.value)
  }
  function formDetailOk(){
    if (!replyContent) {
      message.error('输入不能为空！')
      return
    }
    props.postReply(ticketID,replyContent)
  }
  function formDetailCancel(){
    setReplyContent('')
    setIsFormDetail(false)
  }

  const columns = isAdmin ? 
  [
    {
      title: '工单编号',
      dataIndex: 'id',
      render: (text, record) => {
        return (
            <a onClick={()=>detailTicketClick(record.posts)}>{record.id}</a>
        )
      }
    },
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '发起人',
      dataIndex: 'authorName',
    },
    {
      title: '发起时间',
      dataIndex: 'time',
      render: time => {
        return (
          <span>{moment(time).format('YYYY-MM-DD HH:MM:SS')}</span>
        )
      }
    },
    // {
    //   title: '关闭时间',
    //   dataIndex: '关闭时间',
    // },
    {
      title: '状态',
      dataIndex: 'closed',
      render: (closed) => {
        return (
          closed ?
            <span>完成</span>
            :
            <span>进行中</span>
        )
      }
    },
    {
      title: '操作',
      dataIndex: '操作',
      render: (text, record) => {
        return (
          record.closed ?
          <span>已结束</span>
          :
          <div>
            <Popconfirm title="关闭?" onConfirm={closeTicket}>
              <a>关闭</a>
            </Popconfirm>
          </div>
        )
      }
    }
  ]
  :
  [
    {
      title: '工单编号',
      dataIndex: 'id',
      render: (text, record) => {
        return (
            <a onClick={()=>detailTicketClick(record.posts)}>{record.id}</a>
        )
      }
    },
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '发起人',
      dataIndex: 'authorName',
    },
    {
      title: '发起时间',
      dataIndex: 'time',
      render: time => {
        return (
          <span>{moment(time).format('YYYY-MM-DD HH:MM:SS')}</span>
        )
      }
    },
    // {
    //   title: '关闭时间',
    //   dataIndex: '关闭时间',
    // },
    {
      title: '状态',
      dataIndex: 'closed',
      render: (text, record) => {
        return (
          record.closed ?
          <span>已结束</span>
          :
          <div>
            <Popconfirm title="关闭?" onConfirm={closeTicket}>
              <a>关闭</a>
            </Popconfirm>
          </div>
        )
      }
    },
  ]
  
  // 分页
  function changePage(page, pageSize){
    if (isAdmin) {
      props.getTicket(page - 1, pageSize)
    } else {
      props.getTicket(page - 1, pageSize, localStorage.getItem('user_id'))
    }
    
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SiderPage /> */}
      <Layout>
        <HeaderPage style={{ background: '#fff', padding: 0 }} history={props.history}/>
        <Content className="content">
          {
            loading ?
              <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
                <LoadingPage />
              </div>
              :
              <div style={{ padding: 24, background: '#fff', minHeight: '100%' }}>
                <Row type="flex" justify="start">
                  {/* <Col className="content-filter">
                    <span className="task">工单状态: </span>
                    <Select defaultValue="false" style={{ width: 90 }} onChange={handleChange}>
                      <Option value="false">未关闭</Option>
                      <Option value="true">已关闭</Option>
                    </Select>
                  </Col> */}
                  <Col className="content-filter">
                    <span className="task">开始时间: </span>
                    <DatePicker placeholder="Select Time" onChange={startTimeChange} />
                  </Col>
                  <Col className="content-filter">
                    <span className="task">结束时间: </span>
                    <DatePicker placeholder="Select Time" onChange={endTimeChange} />
                  </Col>
                  <Col className="content-filter">
                    <Button type="primary" shape="round" icon="search" onClick={ticketSearch}>搜索</Button>
                  </Col>
                  {
                    isAdmin ? 
                      null
                      :
                      <Col className="content-filter">
                        <Button type="primary" shape="round" icon="plus" onClick={newTicket}>新建工单</Button>
                      </Col>
                  }
                </Row>
                
                <Table className="table-info" rowKey={record => record.id} columns={columns} dataSource={tickets} bordered pagination={false} onRow={rowRecord}/>
                <Pagination style={{ marginBottom: 10, marginTop: 10}} defaultCurrent={1} total={totalCounts} pageSize={20} onChange={changePage}/>
              </div>
          }
          
          <Modal
            title="新建工单"
            visible={isCreateTicket}
            onOk={newTicketOk}
            onCancel={newTicketCancel}
            width={900}
            confirmLoading={confirmLoading}
          >
            <Row className="content">
              <Col span={4}>
              <span>Title:</span>
              </Col>
              <Col span={20}>
                <Input
                  name="title"
                  onChange={titleChange}
                  />
              </Col>
            </Row>

            <Row className="content">
              <Col span={4}>
              <span>Content:</span>
              </Col>
              <Col span={20}>
                <Input.TextArea
                  name="content"
                  rows={10}
                  onChange={contentChange}
                  />
              </Col>
            </Row>
          </Modal>

          <Modal
            title="工单详情"
            visible={isFormDetail}
            footer={ticketClosed ? null : <Button type="primary" onClick={formDetailOk}>OK</Button>}
            onCancel={formDetailCancel}
            width={900}
          >
            <List
              itemLayout="horizontal"
              dataSource={posts}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                    title={<a href="https://ant.design">{item.authorName}</a>}
                    description={item.content}
                  />
                <span className="formTime">{moment(item.time).format('YYYY-MM-DD HH:MM:SS')}</span>
                </List.Item>
              )}
            />
            {
              ticketClosed ?
                null
                :
                <div>
                  <span className="formReplay">回复:</span>
                  <Input.TextArea className="formInput" rows={10} onChange={handleReplyChange} value={replyContent}></Input.TextArea>
                </div>
            }
          </Modal>
          
        </Content>
        <FooterPage />
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    userCheckToken: state.userReducer.userCheckToken,
    getTicketInfo: state.ticketReducer.getTicketInfo,
    newTicketInfo: state.ticketReducer.newTicketInfo,
    newReplyInfo: state.ticketReducer.newReplyInfo,
    closeTicketInfo: state.ticketReducer.closeTicketInfo,
  }
}

const mapDsipatchToProps = (dispatch) => {
  return {
    getTicket: bindActionCreators(FormActionCreators.GetTicketAction, dispatch),
    searchTicket: bindActionCreators(FormActionCreators.SearchTicketAction, dispatch),
    postTicket: bindActionCreators(FormActionCreators.PostFormAction, dispatch),
    postReply: bindActionCreators(FormActionCreators.PostReplyAction, dispatch),
    closeTicket: bindActionCreators(FormActionCreators.CloseFormAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDsipatchToProps)(FormPage)

