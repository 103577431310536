import { FormActionTypes } from '../action/formAction'

const ticketReducer = (state = {
  getTicketInfo: {},
  newTicketInfo: {},
  newReplyInfo: {},
  closeTicketInfo: {},
}, action) => {
  switch(action.type){
    case FormActionTypes.GET_TICKET:
      return Object.assign({}, state, { getTicketInfo: action.payload })
    case FormActionTypes.POST_NEW_TICKET:
      return Object.assign({}, state, { newTicketInfo: action.payload })
    case FormActionTypes.POST_NEW_REPLY:
      return Object.assign({}, state, { newReplyInfo: action.payload })
    case FormActionTypes.CLOSE_TICKET:
      return Object.assign({}, state, { closeTicketInfo: action.payload })
    default:
      return state
  }
}
export default ticketReducer