import { combineReducers} from 'redux'
import userReducer from './userReducer.js'
import taskReducer from './taskReducer.js'
import instanceReducer from './instanceReducer.js'
import infoReducer from './infoReducer.js'
import ticketReducer from './ticketReducer.js'

const rootReducer = combineReducers(
  {
    userReducer:userReducer,
    taskReducer:taskReducer,
    instanceReducer:instanceReducer,
    infoReducer:infoReducer,
    ticketReducer:ticketReducer,
  }
)

export default rootReducer