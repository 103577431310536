import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Sider from './components/Sider/Sider'
import Home from './container/Home/Home'
import Login from './container/Login/Login'
import Task from './container/Task/Task'
import Cost from './container/Cost/Cost'
import FormPage from './container/FormPage/FormPage'
import InfoPage from './container/InfoPage/InfoPage'
import UserPage from './container/UserPage/UserPage'
import InstancePage from './container/InstancePage/InstancePage'
import ForgotPasswordPage from './container/ForgotPassword/ForgotPassword'
// export default class Router extends Component {
//     render() {
//         return (
//             <BrowserRouter>
//                 <Switch>
//                     <Route exact path="/" component={Home}/>
//                     <Route exact path="/login" component={Login}/>
//                     <Route exact path="/home" component={Home}/>
//                     <Route exact path="/task/info" component={Task}/>
//                     <Route exact path="/cost/info" component={Cost}/>
//                     <Route exact path="/form/info" component={FormPage}/>
//                     <Route exact path="/info/info" component={InfoPage}/>
//                     <Route exact path="/user/info" component={UserPage}/>
//                     <Route exact path="/instance/info" component={InstancePage}/>
//                     <Route exact path="/user/checkuser" component={ForgotPasswordPage}/>
//                 </Switch>
//             </BrowserRouter>
//         )
//     }
// }

const ChildRoute = () => (
    <div>
      <Route path="*" component={Sider} />
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/home" component={Home}/>
        <Route exact path="/task/info" component={Task}/>
        <Route exact path="/cost/info" component={Cost}/>
        <Route exact path="/form/info" component={FormPage}/>
        <Route exact path="/info/info" component={InfoPage}/>
        <Route exact path="/user/info" component={UserPage}/>
        <Route exact path="/instance/info" component={InstancePage}/>
        <Route exact path="/user/checkuser" component={ForgotPasswordPage}/>
      </Switch>   
    </div>
  )
  const Router = () => {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ChildRoute />
        </Switch>
      </BrowserRouter>
    )
  }
  
  export default Router
