import React, { useState } from 'react';

import './Footer.css';
import 'antd/dist/antd.css';
import { Layout } from 'antd';

const { Footer } = Layout;

function FooterPage() {
  return (
     <Footer style={{ textAlign: 'center' }}>ZukVNC ©2019 Created by ZukVNC</Footer>
  );
}

export default FooterPage;


